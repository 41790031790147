import React from 'react'
import {
  MDBCol,
  MDBContainer,
  MDBRow,
  MDBFooter,
  MDBBtn,
  MDBIcon,
} from 'mdbreact'
import { Link } from 'gatsby'

const Footer = () => {
  return (
    <MDBFooter className="font-small pt-0">
      <MDBContainer>
        <MDBRow className="pt-5 mb-3 text-center d-flex justify-content-center">
          <MDBCol md="2" className="b-3">
            <h6 className="title">
              <a href="/">Home</a>
            </h6>
          </MDBCol>
          <MDBCol md="2" className="b-3">
            <h6 className="title">
              <Link to="/works">How it Works</Link>
            </h6>
          </MDBCol>
          <MDBCol md="2" className="b-3">
            <h6 className="title">
              <Link to="/team">Team</Link>
            </h6>
          </MDBCol>
          {/* <MDBCol md="2" className="b-3">
            <h6 className="title">
              <Link to="#!">Help</Link>
            </h6>
          </MDBCol>
          <MDBCol md="2" className="b-3">
            <h6 className="title">
              <Link to="#!">Company</Link>
            </h6>
          </MDBCol> */}
        </MDBRow>
        <hr className="rgba-white-light" style={{ margin: '0 15%' }} />
        <MDBRow className="d-flex text-center justify-content-center mb-md-0 mb-4">
          <MDBCol md="8" sm="12" className="mt-5">
            <p style={{ lineHeight: '1.7rem' }}>
              HearRo is a blockchain powered phone system that creates trusted
              connections using secure digital identities. It gives everyone an
              effortless way to interact over unique, private connections that
              you control.
            </p>
          </MDBCol>
        </MDBRow>
        <hr
          className="clearfix d-md-none rgba-white-light"
          style={{ margin: '10% 15% 5%' }}
        />
        <MDBRow className="pb-3">
          <MDBCol md="12">
            <div className="mb-5 flex-center">
            <a
                href="https://www.facebook.com/hearroinc/"
                target="_blank"
                rel="noopener noreferrer"
              >
              <MDBIcon
                fab
                icon="facebook-f"
                size="lg"
                className="mx-2 white-text mr-md-4"
              ></MDBIcon>
              </a>
              <a
                href="https://twitter.com/HearroInc"
                target="_blank"
                rel="noopener noreferrer"
              >
                <MDBIcon
                  fab
                  icon="twitter"
                  size="lg"
                  className="mx-2 white-text mr-md-4"
                ></MDBIcon>
              </a>
              <a
                href="https://www.linkedin.com/company/hearro/"
                target="_blank"
                rel="noopener noreferrer"
              >
                <MDBIcon
                  fab
                  icon="linkedin-in"
                  size="lg"
                  className="mx-2 white-text mr-md-4"
                ></MDBIcon>
              </a>
            </div>
          </MDBCol>
        </MDBRow>
      </MDBContainer>
      <div className="footer-copyright text-center py-3">
        <MDBContainer fluid>
          &copy; {new Date().getFullYear()} Copyright:
          <a href="https://www.hearro.com" alt="">
            {' '}
            HearRo, Inc.{' '}
          </a>
        </MDBContainer>
      </div>
    </MDBFooter>
  )
}

export default Footer
