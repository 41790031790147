import React from 'react'
import Layout from '../components/layout'
import '../assets/scss/mdb.scss'
import {
  MDBRow,
  MDBCol,
  MDBCard,
  MDBCardBody,
  MDBContainer,
  MDBMask,
  MDBView,
  MDBBtn,
} from 'mdbreact'
import Vic_image from '../images/vic_cooper.jpg'
import Evan_image from '../images/evan.jpg'
import James_image from '../images/james.jpeg'
import Cathy_image from '../images/cathy.jpeg'

const TeamPage = () => {
  return (
    <Layout>
      <MDBCard className="my-5 px-5 pb-5">
        <MDBCardBody>
          <h2 className="h1-responsive  text-center my-5">The HearRo Team</h2>
          <p className="text-center w-responsive mx-auto mb-5">
            OK, we admit, we're building HearRo for purely selfish reasons. We
            have our own horror stories to tell when it comes to the
            overwhelming frustration dealing with call centers and customer
            service departments. Given the reaction we are getting from our
            customers, we aren't the only ones feeling the pain.
          </p>
          <MDBContainer>
          <MDBRow>
            <MDBCol lg="4" xl="4">
              {/* <MDBView hover > */}
                <img
                  className="img-fluid"
                  src={Vic_image}
                  alt=""
                  className="rounded z-depth-1-half"
                />
                {/* <a href="#!">
                  <MDBMask overlay="white-slight" />
                </a>
              </MDBView> */}
            </MDBCol>
            <MDBCol lg="8" xl="8">
              <h3 className=" mb-3 p-0">Vic Cooper - Founder &amp; CEO</h3>
              <p className="dark-grey-text">
                Vic was a contact center architect and consultant for over 16
                years before starting HearRo. His experience and frustration
                with designing, programming and implementing major contact
                center applications for leading healthcare, finance, education
                and enterprise clients was the inspiration.
                <blockquote className="blockquote">
                  <em>
                    "After all these years working as a call center consultant, I
                    realized that businesses all talked about how this or that
                    feature (or system) would enable better customer service but
                    the solutions never seemed to make dent in the real world
                    experience their customers actually had. I also realized
                    that the problem was bigger than what one company could
                    solve on their own.""
                  </em>
                </blockquote>
              </p>
            </MDBCol>
          </MDBRow>
          <hr className="my-5" />
          <MDBRow>
            <MDBCol lg="4" xl="4">
              <MDBView hover rounded className="z-depth-1-half mb-4">
                <img
                  className="img-fluid"
                  src={Evan_image}
                  alt=""
                  
                />
                {/* <a href="#!">
                  <MDBMask overlay="white-slight" />
                </a> */}
              </MDBView>
            </MDBCol>
            <MDBCol lg="8" xl="8">
              <h3 className=" mb-3 p-0">Evan Goldman – Partner &amp; Chief Strategy Officer</h3>
              <p className="dark-grey-text">
              Evan is a transactional attorney and business consultant with over 20 years of experience founding and supporting innovative US and international based startups. Among other companies, Evan has served in corporate development and legal roles for ZutaLabs (mobile robotics), Umoove (mobile eye tracking software), BrightSource (utility scale solar) and InsureWorx (enterprise software).
                {/* <blockquote className="blockquote">
                  <em>
                    After all these years working as a call center consultant, I
                    realized that businesses all talked about how this or that
                    feature (or system) would enable better customer service but
                    the solutions never seemed to make dent in the real world
                    experience their customers actually had. I also realized
                    that the problem was bigger than what one company could
                    solve on their own.
                  </em>
                </blockquote> */}
              </p>
            </MDBCol>
          </MDBRow>
          <hr className="my-5" />
          <MDBRow>
            <MDBCol lg="4" xl="4">
            <MDBView hover rounded className="z-depth-1-half mb-4">
                <img
                  className="img-fluid"
                  src={James_image}
                  alt=""
                  className="rounded z-depth-1-half"
                />
                </MDBView>
            </MDBCol>
            <MDBCol lg="8" xl="8">
              <h3 className=" mb-3 p-0">James Thomson – Partner &amp; CTO</h3>
              <p className="dark-grey-text">
              James is a software developer and business consultant with over 20 years of experience founding and leading successful software companies. In 2003 James founded ServiceLogix with the goal of developing highly customized cloud hosted mobile and web delivered applications in a variety of verticals including vendor and resource management, finance, insurance, education, green manufacturing, personalized medicine and more.
                {/* <blockquote className="blockquote">
                  <em>
                    After all these years working as a call center consultant, I
                    realized that businesses all talked about how this or that
                    feature (or system) would enable better customer service but
                    the solutions never seemed to make dent in the real world
                    experience their customers actually had. I also realized
                    that the problem was bigger than what one company could
                    solve on their own.
                  </em>
                </blockquote> */}
              </p>
            </MDBCol>
          </MDBRow>
          <hr className="my-5" />
          <MDBRow>
            <MDBCol lg="4" xl="4">
            <MDBView hover rounded className="z-depth-1-half mb-4">
                <img
                  className="img-fluid"
                  src={Cathy_image}
                  alt=""
                  className="rounded z-depth-1-half"
                />
                </MDBView>
            </MDBCol>
            <MDBCol lg="8" xl="8">
              <h3 className=" mb-3 p-0">Cathy Connors - Product Manager</h3>
              <p className="dark-grey-text">
              Cathy is an innovative Senior Contact Center Specialist with excellent technical and management skills.  With over 19 years of experience planning, engineering, and implementing unified, global systems for Fortune 500 Companies, she is a valued product manager with strategic vision. Her frustration over the inability to provide excellent call-center customer service is what drew her to the HearRo team.  She strongly believes that blockchain technologies are the way of the future.  
                <blockquote className="blockquote">
                  <em>
                  Cathy also thinks that IVRs are a thing of a past, therefore all auto attendants should be kicked to the curb.  Press 1 if you agree.  Press 2 if you disagree.
                  </em>
                </blockquote>
              </p>
            </MDBCol>
          </MDBRow>
          </MDBContainer>
        </MDBCardBody>
      </MDBCard>
    </Layout>
  )
}

export default TeamPage
