import React, { Component } from 'react'
import {
  MDBContainer,
  MDBNavbar,
  MDBNavbarNav,
  MDBBtn,
  MDBSmoothScroll,
  // MDBNavLink,
  MDBNavbarToggler,
  MDBCollapse,
  // MDBFormInline,
  // MDBDropdown,
  // MDBDropdownToggle,
  // MDBDropdownMenu,
  // MDBDropdownItem,
  MDBIcon,
} from 'mdbreact'
import { Link } from 'gatsby'
//import Link from './customLink'
import { ReactComponent as Logo } from '../images/hearro_white_shield_only.svg'
import { ReactComponent as Wordmark } from '../images/hearro_wordmark_white.svg'

class NavbarPage extends Component {
  state = {
    isOpen: false,
  }

  toggleCollapse = () => {
    this.setState({ isOpen: !this.state.isOpen })
  }

  render() {
    return (
      <MDBNavbar
        color="indigo"
        dark
        expand="md"
        fixed="top"
        scrolling
        // transparent
      >
        <MDBContainer>
          <Logo />
          <Link to="/" className="navbar-brand">
            <Wordmark />
            {/* <strong className="ml-3 white-text">HearRo</strong> */}
          </Link>
          <MDBNavbarToggler
            name="navbar-toggler"
            onClick={this.toggleCollapse}
          />
          <MDBCollapse id="navbarCollapse3" isOpen={this.state.isOpen} navbar>
            <MDBNavbarNav left></MDBNavbarNav>
            <MDBNavbarNav right>
              <div className="d-flex align-items-center">
                <Link to="/works" className="ml-1 white-text">
                  {/* <MDBSmoothScroll active to="how1"> */}
                    How It Works
                  {/* </MDBSmoothScroll> */}
                </Link>
                <pre> </pre>
                <Link to="/team" className="ml-1 white-text">
                  Team
                </Link>
                <pre> </pre>
                <Link to="/contact">
                <MDBBtn
                  color="orange accent-4"
                  rounded
                  size="sm"
                  // onClick={this.toggle(4)}
                >
                  Schedule a Demo
                </MDBBtn>
                </Link>
                <pre> </pre>
                <a
                  href="https://www.facebook.com/hearroinc/"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <MDBIcon fab icon="facebook" className="ml-1 white-text" />
                </a>
                <a
                  href="https://twitter.com/HearroInc"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <MDBIcon fab icon="twitter" className="ml-1" />
                </a>
                <a
                  href="https://www.linkedin.com/company/hearro/"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <MDBIcon fab icon="linkedin" className="ml-1 white-text" />
                </a>
              </div>
            </MDBNavbarNav>
          </MDBCollapse>
        </MDBContainer>
      </MDBNavbar>
    )
  }
}

export default NavbarPage
